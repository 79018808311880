import { navigate } from 'gatsby';
import {
  Mesh, Program, Texture,
} from 'ogl';
import React, {
  Component,
} from 'react';

import fragment from './shaders/image-fragment.glsl';
import vertex from './shaders/image-vertex.glsl';
import { map } from './utils/math';

export class Media extends Component {
  constructor({
    // eslint-disable-next-line react/prop-types
    geometry, gl, image, index, length, renderer, scene, screen, slug, viewport,
  }) {
    super();
    this.extra = 0;

    this.geometry = geometry;
    this.gl = gl;
    this.image = image;
    this.index = index;
    this.length = length;
    this.renderer = renderer;
    this.scene = scene;
    this.screen = screen;
    this.slug = slug;
    this.viewport = viewport;

    this.createShader();
    this.createMesh();

    this.onResize();
  }

  createShader() {
    const texture = new Texture(this.gl, {
      generateMipmaps: false,
    });

    this.program = new Program(this.gl, {
      depthTest: false,
      depthWrite: false,
      fragment,
      vertex,
      uniforms: {
        tMap: { value: texture },
        uPlaneSizes: { value: [0, 0] },
        uImageSizes: { value: [0, 0] },
        uViewportSizes: { value: [this.viewport.width, this.viewport.height] },
        uSpeed: { value: 0 },
        uTime: { value: 100 * Math.random() },
        uHit: { value: 0 },
      },
      transparent: true,
    });

    const image = new Image();

    image.src = this.image;
    image.onload = () => {
      texture.image = image;

      this.program.uniforms.uImageSizes.value = [image.naturalWidth, image.naturalHeight];
    };
  }

  createMesh() {
    this.plane = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
    });

    this.plane.setParent(this.scene);

    // assign callback func
    this.plane.callback = () => {
      navigate(`/work/${this.slug}`);
    };

    // assign update functions to each mesh so they can share a program but
    // still have unique uniforms by updating them just before being drawn
    const updateHitUniform = ({ mesh }) => {
      this.program.uniforms.uHit.value = mesh.isHit ? 1 : 0;
    };
    this.plane.onBeforeRender(updateHitUniform);
  }

  update(scroll, direction) {
    this.plane.position.x = this.x - scroll.current - this.extra;
    this.plane.position.y = Math.cos(
      (this.plane.position.x / this.widthTotal) * Math.PI,
    ) * 75 - 74.5;
    this.plane.rotation.z = map(
      this.plane.position.x, -this.widthTotal, this.widthTotal, Math.PI, -Math.PI,
    );

    this.speed = scroll.current - scroll.last;

    this.program.uniforms.uTime.value += 0.04;
    this.program.uniforms.uSpeed.value = this.speed;

    const planeOffset = this.plane.scale.x / 2;
    const viewportOffset = this.viewport.width;

    this.isBefore = this.plane.position.x + planeOffset < -viewportOffset;
    this.isAfter = this.plane.position.x - planeOffset > viewportOffset;

    if (direction === 'right' && this.isBefore) {
      this.extra -= this.widthTotal;

      this.isBefore = false;
      this.isAfter = false;
    }

    if (direction === 'left' && this.isAfter) {
      this.extra += this.widthTotal;

      this.isBefore = false;
      this.isAfter = false;
    }
  }

  onResize({ screen, viewport } = {}) {
    if (screen) {
      this.screen = screen;
    }

    if (viewport) {
      this.viewport = viewport;

      this.plane.program.uniforms.uViewportSizes.value = [
        this.viewport.width,
        this.viewport.height,
      ];
    }

    this.scale = this.screen.height / 1500;

    // eslint-disable-next-line no-mixed-operators
    this.plane.scale.y = this.viewport.height * (810 * this.scale) / this.screen.height;
    // eslint-disable-next-line no-mixed-operators
    this.plane.scale.x = this.viewport.width * (720 * this.scale) / this.screen.width;

    this.plane.program.uniforms.uPlaneSizes.value = [this.plane.scale.x, this.plane.scale.y];

    this.padding = 2;

    this.width = this.plane.scale.x + this.padding;
    this.widthTotal = this.width * this.length;

    this.x = this.width * this.index;
  }

  render() {
    return (
      <>
      </>
    );
  }
}
