import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { Slider } from '../components/content/slider';
import { Metadata } from '../components/core/metadata';
import { create } from '../helpers/bem';

import './work.scss';

const bem = create('page-work');

const WorkPage = () => {
  const {
    allMdx: { edges },
  } = useStaticQuery(graphql`
    query {
      allMdx(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {fileAbsolutePath: {regex: "/(work)/.*\\.mdx$/"}}
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              intro
              date
              color
              frameImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);
  const { t } = useTranslation();

  return (
    <Metadata
      title={t('Client work')}
      description="Client work of Frontend Developer Yves Rijckaert."
    >
      <GroupAnimation as="article" className={bem()}>
        <GroupedBlockAnimation as="h2" delay={500} className={bem('headline')}>{t('Client work')}</GroupedBlockAnimation>
        <Slider items={edges} />
      </GroupAnimation>
    </Metadata>
  );
};

export default WorkPage;
