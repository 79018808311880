import * as TWEEN from '@tweenjs/tween.js';
import { Link } from 'gatsby';
import debounce from 'lodash/debounce';
import NormalizeWheel from 'normalize-wheel';
import {
  Camera, Plane, Raycast, Renderer, Transform, Vec2,
} from 'ogl';
import { arrayOf, shape } from 'prop-types';
import React, { Component } from 'react';
import { useMediaQuery } from 'react-responsive';

import { create } from '../../../helpers/bem';
import { mdxType } from '../../../types/mdx';
import { Carousel } from '../carousel';

import Image1 from './images/abb.png';
import Image5 from './images/gruene.png';
import Image4 from './images/ias.png';
import Image3 from './images/inuvia.png';
import Image2 from './images/medskin.png';
import { Media } from './media';
import { lerp } from './utils/math';

import './slider.scss';

const bem = create('content-slider');

// duplicated the images and added a copy boolean for the copies
// this is because the slider has to have a certain amount of items in order for it to look good
// todo - delete the copies once there are enough projects ...
const mediasImages = [
  { image: Image1, slug: 'abb', title: 'ABB' },
  { image: Image2, slug: 'medskin', title: 'MedSkin Solutions Dr. Suwelack' },
  { image: Image3, slug: 'inuvia', title: 'inuvia Health Products' },
  { image: Image4, slug: 'ias', title: 'ias-Gruppe' },
  { image: Image5, slug: 'gruene', title: 'Bündnis 90/Die Grünen' },
  {
    image: Image1, slug: 'abb', title: 'ABB', copy: true,
  },
  {
    image: Image2, slug: 'medskin', title: 'MedSkin Solutions Dr. Suwelack', copy: true,
  },
  {
    image: Image3, slug: 'inuvia', title: 'inuvia Health Products', copy: true,
  },
  {
    image: Image4, slug: 'ias', title: 'ias-Gruppe', copy: true,
  },
  {
    image: Image5, slug: 'gruene', title: 'Bündnis 90/Die Grünen', copy: true,
  },
  {
    image: Image1, slug: 'abb', title: 'ABB', copy: true,
  },
  {
    image: Image2, slug: 'medskin', title: 'MedSkin Solutions Dr. Suwelack', copy: true,
  },
  {
    image: Image3, slug: 'inuvia', title: 'inuvia Health Products', copy: true,
  },
  {
    image: Image4, slug: 'ias', title: 'ias-Gruppe', copy: true,
  },
  {
    image: Image5, slug: 'gruene', title: 'Bündnis 90/Die Grünen', copy: true,
  },
  {
    image: Image1, slug: 'abb', title: 'ABB', copy: true,
  },
  {
    image: Image2, slug: 'medskin', title: 'MedSkin Solutions Dr. Suwelack', copy: true,
  },
  {
    image: Image3, slug: 'inuvia', title: 'inuvia Health Products', copy: true,
  },
  {
    image: Image4, slug: 'ias', title: 'ias-Gruppe', copy: true,
  },
  {
    image: Image5, slug: 'gruene', title: 'Bündnis 90/Die Grünen', copy: true,
  },
];

class WebglSlider extends Component {
  constructor() {
    super();
    this.scroll = {
      ease: 0.05,
      current: 0,
      target: 0,
      last: 0,
    };

    this.onCheckDebounce = debounce(this.onCheck, 200);

    this.onResize = this.onResize.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.onTouchDown = this.onTouchDown.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchUp = this.onTouchUp.bind(this);

    this.onFocus = this.onFocus.bind(this);
    document.body.style.overflow = 'hidden';
  }

  componentDidMount() {
    this.createRenderer();
    this.createCamera();
    this.createScene();

    this.onResize();

    this.createGeometry();
    this.createMedias();
    this.createMouse();
    this.createRaycast();

    this.update();

    this.addEventListeners();
    this.createPreloader();
  }

  createPreloader() {
    mediasImages.forEach(({ image: source }) => {
      const image = new Image();

      this.loaded = 0;

      image.src = source;
      image.onload = () => {
        this.loaded += 1;

        if (this.loaded === mediasImages.length) {
          this.mount.classList.remove('content-slider--loading');
          this.mount.classList.add('content-slider--loaded');
          this.loaderAnimation.start();
        }
      };
    });
  }

  createRenderer() {
    this.renderer = new Renderer({ alpha: true, antialias: true, dpr: window.devicePixelRatio });

    this.gl = this.renderer.gl;

    this.mount.appendChild(this.gl.canvas);
  }

  createCamera() {
    this.camera = new Camera(this.gl);
    this.camera.fov = 45;
    this.camera.position.z = 20;
    this.camera.position.y = 0;

    const position = { y: 10 };

    this.loaderAnimation = new TWEEN.Tween(position)
      .to({ y: 1 }, 1200)
      .easing(TWEEN.Easing.Quartic.InOut)
      .onUpdate(() => {
        this.camera.position.y = position.y;
      });
  }

  createScene() {
    this.scene = new Transform();
  }

  createGeometry() {
    this.planeGeometry = new Plane(this.gl, {
      heightSegments: 50,
      widthSegments: 100,
    });
  }

  createMedias() {
    this.medias = mediasImages.map(({ image, slug }, index) => {
      const media = new Media({
        geometry: this.planeGeometry,
        gl: this.gl,
        image,
        index,
        length: mediasImages.length,
        renderer: this.renderer,
        scene: this.scene,
        screen: this.screen,
        slug,
        viewport: this.viewport,
      });

      return media;
    });
  }

  createMouse() {
    this.mouse = new Vec2();
  }

  createRaycast() {
    // Create a raycast object
    this.raycast = new Raycast(this.gl);
    // Define an array of the meshes we want to test our ray against
    // this.meshes = [this.planeGeometry];
    this.meshes = this.medias.map((media) => media.plane);
  }

  onTouchDown(event) {
    if (this.loaded !== mediasImages.length) {
      return;
    }
    document.body.style.removeProperty('cursor');

    this.isDown = true;

    this.scroll.position = this.scroll.current;
    this.start = event.touches ? event.touches[0].clientX : event.clientX;

    this.mouse.set(
      2.0 * (event.x / this.renderer.width) - 1.0,
      2.0 * (1.0 - event.y / this.renderer.height) - 1.0,
    );

    // Update the ray's origin and direction using the camera and mouse
    this.raycast.castMouse(this.camera, this.mouse);

    // raycast.intersectBounds will test against the bounds of each mesh, and
    // return an array of intersected meshes in order of closest to farthest
    const hits = this.raycast.intersectBounds(this.meshes);

    // Update our feedback using this array
    // eslint-disable-next-line no-return-assign
    hits.forEach((mesh) => {
      // eslint-disable-next-line no-param-reassign
      mesh.callback();
    });
  }

  onTouchMove(event) {
    if (this.loaded !== mediasImages.length) {
      return;
    }
    this.mouse.set(
      2.0
      * (event.x / this.renderer.width) - 1.0, 2.0 * (1.0 - event.y / this.renderer.height) - 1.0,
    );

    // Update the ray's origin and direction using the camera and mouse
    this.raycast.castMouse(this.camera, this.mouse);

    // Reset each mesh's hit to false
    // eslint-disable-next-line no-param-reassign, no-return-assign
    this.meshes.forEach((mesh) => (mesh.isHit = false));

    // raycast.intersectBounds will test against the bounds of each mesh, and
    // return an array of intersected meshes in order of closest to farthest
    const hits = this.raycast.intersectBounds(this.meshes);

    // Update our feedback using this array
    if (hits && hits.length > 0) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }

    // Update our feedback using this array
    // eslint-disable-next-line no-return-assign
    hits.forEach((mesh) => {
      // eslint-disable-next-line no-param-reassign
      mesh.isHit = true;
    });

    if (!this.isDown) return;

    const x = event.touches ? event.touches[0].clientX : event.clientX;
    const distance = (this.start - x) * 0.01;

    this.scroll.target = this.scroll.position + distance;
  }

  onTouchUp() {
    this.isDown = false;

    this.onCheck();
  }

  onWheel(event) {
    const normalized = NormalizeWheel(event);
    const speed = normalized.pixelY;

    this.scroll.target += speed * 0.005;

    this.onCheckDebounce();
  }

  onCheck() {
    const { width } = this.medias[0];
    const itemIndex = Math.round(Math.abs(this.scroll.target) / width);
    const item = width * itemIndex;

    if (this.scroll.target < 0) {
      this.scroll.target = -item;
    } else {
      this.scroll.target = item;
    }
  }

  onResize() {
    this.screen = {
      height: window.innerHeight,
      width: window.innerWidth,
    };

    this.renderer.setSize(this.screen.width, this.screen.height);

    this.camera.perspective({
      aspect: this.gl.canvas.width / this.gl.canvas.height,
    });

    const fov = this.camera.fov * (Math.PI / 180);
    const height = 2 * Math.tan(fov / 2) * this.camera.position.z;
    const width = height * this.camera.aspect;

    this.viewport = {
      height,
      width,
    };

    if (this.medias) {
      this.medias.forEach((media) => media.onResize({
        screen: this.screen,
        viewport: this.viewport,
      }));
    }
  }

  onFocus(event) {
    const { id } = event.currentTarget.dataset;
    const { width } = this.medias[0];
    this.scroll.target = id * width;
  }

  update() {
    if (this.loaded === mediasImages.length) {
      TWEEN.update();
    }

    this.scroll.current = lerp(this.scroll.current, this.scroll.target, this.scroll.ease);

    if (this.scroll.current > this.scroll.last) {
      this.direction = 'right';
    } else {
      this.direction = 'left';
    }

    if (this.medias) {
      this.medias.forEach((media) => media.update(this.scroll, this.direction));
    }

    this.renderer.render({
      scene: this.scene,
      camera: this.camera,
    });

    this.scroll.last = this.scroll.current;

    this.frameId = window.requestAnimationFrame(this.update.bind(this));
  }

  addEventListeners() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('mousewheel', this.onWheel);
    window.addEventListener('wheel', this.onWheel);
    window.addEventListener('mousedown', this.onTouchDown);
    window.addEventListener('mousemove', this.onTouchMove);
    window.addEventListener('mouseup', this.onTouchUp);
    window.addEventListener('touchstart', this.onTouchDown);
    window.addEventListener('touchmove', this.onTouchMove);
    window.addEventListener('touchend', this.onTouchUp);
  }

  removeEventListeners() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('mousewheel', this.onWheel);
    window.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('mousedown', this.onTouchDown);
    window.removeEventListener('mousemove', this.onTouchMove);
    window.removeEventListener('mouseup', this.onTouchUp);
    window.removeEventListener('touchstart', this.onTouchDown);
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchUp);
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  componentWillUnmount() {
    document.body.style.removeProperty('overflow');
    this.removeEventListeners();
    this.planeGeometry.remove();
    this.planeGeometry.remove();
    this.stop();
    this.mount.removeChild(this.renderer.gl.canvas);
  }

  render() {
    return (
      <div
        className={bem()}
        ref={(mount) => { this.mount = mount; }}
      >
        <ol className={bem('list')}>
          {mediasImages.map(({ slug, title, copy }, i) => (
            !copy && (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <Link to={slug} data-id={i} onFocus={this.onFocus}>{title}</Link>
            </li>
            )
          ))}
        </ol>
      </div>

    );
  }
}

export const Slider = ({ items }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)',
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  return (
    <>
      {isDesktopOrLaptop && <WebglSlider />}
      {isTabletOrMobile && (
        <div className="content-carousel-wrap"><Carousel items={items} /></div>
      )}
    </>
  );
};

Slider.propTypes = {
  items: arrayOf(
    shape(
      { node: mdxType },
    ),
  ).isRequired,
};
